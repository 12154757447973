<template>
  <div class="scheme-contain">
    <Row>
      <Col span="6">
        <h2 class="scheme-title">推送简易方案</h2>
      </Col>
      <Col span="8" offset="2">
        <div class="scheme">
          <Tabs value="1" :animated="false" @on-click="handleTabs">
            <Tab-pane label="成药与要素" name="1">
              <div class="element-contain">
                <Form :label-width="85" v-model="DrugRecord">
                  <Form-item label="要素名称：">
                    <Input v-model="DrugRecord.name"></Input>
                  </Form-item>
                  <Form-item label="服用时间：">
                    <Input v-model="DrugRecord.time"></Input>
                  </Form-item>
                  <Form-item label="推荐用量：">
                    <Input v-model="DrugRecord.count"></Input>
                  </Form-item>
                  <div class="symbol-box tac">
                    <Button type="primary" size="large" @click="handleBack">返回</Button>
                    <Button type="success" size="large" @click="handleSendDrug">推送</Button>
                  </div>
                </Form>
              </div>
            </Tab-pane>
            <Tab-pane label="运动记录" name="2">
              <div class="element-contain">
                <Form :label-width="85">
                  <Form-item label="能耗方式：">
                    <Input v-model="Sports.way"></Input>
                  </Form-item>
                  <Form-item label="能耗时间：">
                    <Input v-model="Sports.time"></Input>
                  </Form-item>
                  <Form-item label="能耗强度：">
                    <Input v-model="Sports.strenth"></Input>
                  </Form-item>
                  <Form-item label="能耗频率：">
                    <Input v-model="Sports.rate"></Input>
                  </Form-item>
                  <div class="symbol-box tac">
                    <Button type="primary" size="large" @click="handleBack">返回</Button>
                    <Button type="success" size="large" @click="handleSendSport">推送</Button>
                  </div>
                </Form>
              </div>
            </Tab-pane>
            <Tab-pane label="膳食记录" name="3">
              <div class="element-contain">
                <Form :label-width="85">
                  <Form-item label="早餐：">
                    <Input v-model="Food.breakfast"></Input>
                  </Form-item>
                  <Form-item label="中餐：">
                    <Input v-model="Food.lunch"></Input>
                  </Form-item>
                  <Form-item label="晚餐：">
                    <Input v-model="Food.dinner"></Input>
                  </Form-item>
                  <Form-item label="加餐：">
                    <Input v-model="Food.add"></Input>
                  </Form-item>
                  <Form-item label="其他：">
                    <Input v-model="Food.other"></Input>
                  </Form-item>
                  <div class="symbol-box tac">
                    <Button type="primary" size="large" @click="handleBack">返回</Button>
                    <Button type="success" size="large" @click="handleSendFood">推送</Button>
                  </div>
                </Form>
              </div>
            </Tab-pane>
            <Tab-pane label="训练记录" name="4">
              <div class="element-contain">
                <Form :label-width="110">
                  <Form-item label="每组训练个数：">
                    <Input v-model="Train.number"></Input>
                  </Form-item>
                  <Form-item label="每次训练组数：">
                    <Input v-model="Train.group_count"></Input>
                  </Form-item>
                  <Form-item label="每天训练次数：">
                    <Input v-model="Train.count"></Input>
                  </Form-item>
                  <Form-item label="每周训练天数：">
                    <Input v-model="Train.days"></Input>
                  </Form-item>
                  <div class="symbol-box tac">
                    <Button type="primary" size="large" @click="handleBack">返回</Button>
                    <Button type="success" size="large" @click="handleSendTrain">推送</Button>
                  </div>
                </Form>
              </div>
            </Tab-pane>
          </Tabs>
        </div>
      </Col>
    </Row>
  </div>
</template>
<script type="text/ecmascript-6">
import MemberService from '@/services/memberService';
export default {
	data() {
		return {
			DrugRecord: {
				member_id: '',
				name: '',
				count: '',
				time: '',
			},
			Food: {
				member_id: '',
				other: '',
				lunch: '',
				breakfast: '',
				add: '',
				dinner: '',
			},
			Sports: {
				member_id: '',
				way: '',
				time: '',
				strenth: '',
				rate: '',
			},
			Train: {
				member_id: '',
				number: '',
				group_count: '',
				count: '',
				days: '',
			},
			params: {
				type: '1',
				member_id: '',
			},
		};
	},
	computed: {
		memberId() {
			return this.$route.params.member_id;
		},
	},
	methods: {
		handleBack() {
			this.$router.go(-1);
		},
		handleTabs(index) {
			this.params.type = index;
			if (this.params.type === '1') {
				MemberService.sendQuery(this.params).then((data) => {
					this.DrugRecord.name = data.name;
					this.DrugRecord.count = data.count;
					this.DrugRecord.time = data.time;
				});
			}
			if (this.params.type === '2') {
				MemberService.sendQuery(this.params).then((data) => {
					this.Sports.way = data.way;
					this.Sports.time = data.time;
					this.Sports.strenth = data.strength;
					this.Sports.rate = data.rate;
				});
			}
			if (this.params.type === '3') {
				MemberService.sendQuery(this.params).then((data) => {
					this.Food.breakfast = data.breakfast;
					this.Food.lunch = data.lunch;
					this.Food.dinner = data.dinner;
					this.Food.add = data.add;
					this.Food.other = data.other;
				});
			}
			if (this.params.type === '4') {
				MemberService.sendQuery(this.params).then((data) => {
					this.Train.number = data.number;
					this.Train.group_count = data.group_count;
					this.Train.count = data.degree;
					this.Train.days = data.fate;
				});
			}
		},
		// 推送成药与要素
		handleSendDrug() {
			if (this.validateDrug()) {
				this.DrugRecord.member_id = this.memberId;
				MemberService.sendDrug(this.DrugRecord).then(() => {
					this.$Message.success('成药与要素推送成功');
				});
			}
		},
		validateDrug() {
			if (!this.DrugRecord.name) {
				this.$Message.warning('请输入要素名称');
				return false;
			} else if (!this.DrugRecord.time) {
				this.$Message.warning('请输入服用时间');
				return false;
			} else if (!this.DrugRecord.count) {
				this.$Message.warning('请输入推荐用量');
				return false;
			} else {
				return true;
			}
		},
		// 膳食记录推送
		handleSendFood() {
			if (this.validateFood()) {
				this.Food.member_id = this.memberId;
				MemberService.sendFood(this.Food).then(() => {
					this.$Message.success('膳食记录推送成功');
				});
			}
		},
		validateFood() {
			if (!this.Food.breakfast) {
				this.$Message.warning('请输入早餐');
				return false;
			} else if (!this.Food.lunch) {
				this.$Message.warning('请输入中餐');
				return false;
			} else if (!this.Food.dinner) {
				this.$Message.warning('请输入晚餐');
				return false;
			} else if (!this.Food.add) {
				this.$Message.warning('请输入加餐');
				return false;
			} else if (!this.Food.other) {
				this.$Message.warning('请输入其他');
				return false;
			} else {
				return true;
			}
		},
		// 运动记录推送
		handleSendSport() {
			if (this.validateSports()) {
				this.Sports.member_id = this.memberId;
				MemberService.sendSport(this.Sports).then(() => {
					this.$Message.success('运动记录推送成功');
				});
			}
		},
		validateSports() {
			if (!this.Sports.way) {
				this.$Message.warning('请输入能耗方式');
				return false;
			} else if (!this.Sports.time) {
				this.$Message.warning('请输入能耗时间');
				return false;
			} else if (!this.Sports.strenth) {
				this.$Message.warning('请输入能耗强度');
				return false;
			} else if (!this.Sports.rate) {
				this.$Message.warning('请输入能耗频率');
				return false;
			} else {
				return true;
			}
		},
		// 训练记录推送
		handleSendTrain() {
			if (this.validateTrain()) {
				this.Train.member_id = this.memberId;
				MemberService.sendTrans(this.Train).then(() => {
					this.$Message.success('训练记录推送成功');
				});
			}
		},
		validateTrain() {
			if (!this.Train.number) {
				this.$Message.warning('请输入每组训练个数');
				return false;
			} else if (!this.Train.group_count) {
				this.$Message.warning('请输入每组训练组数');
				return false;
			} else if (!this.Train.count) {
				this.$Message.warning('请输入每天训练次数');
				return false;
			} else if (!this.Train.days) {
				this.$Message.warning('请输入每周训练次数');
				return false;
			} else {
				return true;
			}
		},
	},
	created() {
		this.params.member_id = this.memberId;
		MemberService.sendQuery(this.params).then((data) => {
			this.DrugRecord.name = data.name;
			this.DrugRecord.count = data.count;
			this.DrugRecord.time = data.time;
		});
	},
};
</script>
<style lang="css" scoped>
.scheme-contain .scheme-title {
  padding-left: 26px;
  line-height: 35px;
}
.scheme-contain .scheme {
  padding-top: 35px;
}
</style>
